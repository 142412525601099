import { logger } from '@/modules/loggingService'

const log = logger('modules:cookieService:setCookie')

function setCookie (key: string, string: string): void {
  try {
    if (typeof window !== 'undefined') {
      // set a cookie for 90 days
      document.cookie = `${key}=${string}; max-age=7776000; path=/`
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      log.error(err.message)
      log.error(err.stack)
    }
  }
}

export default setCookie
