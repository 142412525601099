import debug from 'debug'

function logger(namespace: string) {
  if (process.env.NODE_ENV === 'production') {
    return {
      debug: function (message: string | undefined) {
        console.log(`DEBUG [${new Date().toISOString()}] ${namespace} - ${message}`)
      },
      info: function (message: string | undefined) {
        console.log(`INFO [${new Date().toISOString()}] ${namespace} - ${message}`)
      },
      warn: function (message: string | undefined) {
        console.log(`WARN [${new Date().toISOString()}] ${namespace} - ${message}`)
      },
      error: function (message: string | undefined) {
        console.log(`ERROR [${new Date().toISOString()}] ${namespace} - ${message}`)
      }
    }
  } else {
    return {
      debug: function (message: string | undefined) {
        debug(`app: ${namespace}`)(`DEBUG [${new Date().toISOString()}] ${message}`)
      },
      info: function (message: string | undefined) {
        debug(`app: ${namespace}`)(`INFO [${new Date().toISOString()}]  ${message}`)
      },
      warn: function (message: string | undefined) {
        debug(`app: ${namespace}`)(`WARN [${new Date().toISOString()}] ${message}`)
      },
      error: function (message: string | undefined) {
        debug(`app: ${namespace}`)(`ERROR [${new Date().toISOString()}] ${message}`)
      }
    }
  }
}

export default logger
