import { createTheme } from '@mui/material/styles'

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#313131'
    },
    secondary: {
      main: '#C68E3F'
    }
  },
  components: {
    // MuiMenu: {
    //   styleOverrides: {
    //     list: {
    //       // dark mode html data theme dark
    //       backgroundColor: 'orange'
    //     }
    //   }
    // }

  }
})

export default lightTheme
