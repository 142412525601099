import MainLayout from './MainLayout'
import HomeLayout from './HomeLayout'
import ComingSoonLayout from './ComingSoonLayout'

export { MainLayout }
export { HomeLayout }
export { ComingSoonLayout }

const obj = {
  MainLayout,
  HomeLayout,
  ComingSoonLayout
}

export default obj
