import Link from 'next/link'
import Image from 'next/image'
import BreadCrumbBar, { LinkProp } from './BreadCrumbBar'
import styles from './MainHeader.module.css'

function MainHeader (props: { links: LinkProp[] }) {
  return (
    <>
      <div className={styles.headerWrapper}>
        <div className={styles.header}>
          <div className={styles.logo}>
            <Link href={'/'}>
              <Image
                className={styles.logoImage}
                src={'/img/sslm-light.svg'}
                alt={'Stepping Stones International Ministry Logo'}
                width={140.7084}
                height={55.6354}
                priority
              />
            </Link>
          </div>
          <BreadCrumbBar
            links={props.links}
          />
        </div>
      </div>
    </>
  )
}

export default MainHeader
