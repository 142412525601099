import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { IConsent } from '@/modules/consentService/interfaces'
import styles from './ConsentPopup.module.css'

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  // eslint-disable-next-line no-unused-vars
  onClose: (value: string) => void;
}

interface Props {
  // eslint-disable-next-line no-unused-vars
  updateConsent: (form: IConsent) => void
}

const ConsentPopup = ({ updateConsent }: Props) => {
  const [open, setOpen] = React.useState(false)
  const [form, setForm] = React.useState({
    necessary: true,
    analytics: true,
    preferences: true
  })

  const handleClickOpen = () => {
    setOpen(true)
  }

  // eslint-disable-next-line no-unused-vars
  const handleClose = (value: string) => {
    setOpen(false)
    // setSelectedValue(value);
  }

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        className={styles.customDialog}
      >
        <DialogTitle id="alert-dialog-title">
          Analytics Consent
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className={styles.cookieWrapper}>
              <div className={styles.cookieOpt}>
                <div className={styles.toggle}>
                  <Checkbox disabled checked color={'primary'} />
                </div>
                <div>
                  <div className={styles.cookieLabel}>Baseline</div>
                  <div className={styles.cookieDetail}>Baseline anonymized Analytics</div>
                </div>
              </div>

              {/* Necessary */}
              <div className={styles.cookieOpt}>
                <div className={styles.toggle}>
                  <Checkbox
                    color={'primary'}
                    checked={form.necessary}
                    onClick={() => setForm({ ...form, necessary: !form.necessary })}
                  />
                </div>
                <div>
                  <div className={styles.cookieLabel}>Necessary</div>
                  <div className={styles.cookieDetail}>Cookie storage related to the functionality of the website or app e.g. language settings, security such as authentication functionality, fraud prevention, and other user protections.</div>
                </div>
              </div>

              {/* Analytics */}
              <div className={styles.cookieOpt}>
                <div className={styles.toggle}>
                  <Checkbox
                    color={'primary'}
                    checked={form.analytics}
                    onClick={() => setForm({ ...form, analytics: !form.analytics })}
                  />
                </div>
                <div>
                  <div className={styles.cookieLabel}>Analytics</div>
                  <div className={styles.cookieDetail}>Cookie storage related to analytics e.g. visit duration.</div>
                </div>
              </div>

              {/* Preferences */}
              <div className={styles.cookieOpt}>
                <div className={styles.toggle}>
                  <Checkbox
                    color={'primary'}
                    checked={form.preferences}
                    onClick={() => setForm({ ...form, preferences: !form.preferences })}
                  />
                </div>
                <div>
                  <div className={styles.cookieLabel}>Preferences</div>
                  <div className={styles.cookieDetail}>Cookie storage related to personalization e.g. video recommendations</div>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            color={'primary'}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color={'primary'}
            onClick={() => {
              updateConsent(form)
              setOpen(false)
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <div className={styles.wrapper}>
        <div className={styles.innerWrap}>
          <div className={styles.consentWrap}>
            <div className={styles.titleWrap}>
              <h2>Cookie Policy</h2>
              <div className={styles.moreOptions} title='More Options' onClick={handleClickOpen}>
                <div className={styles.iconButton}>
                  <FontAwesomeIcon icon={faEllipsisH} />
                </div>
              </div>
            </div>
            <div className={styles.textBlurb}>
              We use cookies for website analytics helping us detect bugs, broken features, and to understand how people use our website. Since most users who access our site will never meet us in person, analytics tracking help us provide a better experience for everyone. We do not use cookies for advertising or tracking users across the web. By continuing, you agree to the use of cookies. To fine tune cookie usage, see the menu option in the upper corner of this message. If you want to update cookie settings again later, see the settings menu in the footer of our website.</div>
            <div
              className={styles.buttonConsent}
              onClick={() => {
                updateConsent({
                  necessary: true,
                  analytics: true,
                  preferences: true
                })
                setOpen(false)
              }}
            >
              Allow All Cookies
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ConsentPopup
