import { createTheme } from '@mui/material/styles'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#e1e0e1'
    },
    secondary: {
      main: '#648dfe'
    }
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        list: {
          // dark mode html data theme dark
          backgroundColor: 'black'
        }
      }
    }
  }
})

export default darkTheme
