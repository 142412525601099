import { createContext, Dispatch, SetStateAction } from 'react'
import { IRootData } from '@/modules/providerService/RootProvider'

interface IRootContext {
  rootData: IRootData
  setRootData: Dispatch<SetStateAction<IRootData>>
}

const RootContext = createContext<IRootContext>({
  rootData: { user: undefined, theme: '' },
  setRootData: () => {}
})

export default RootContext
