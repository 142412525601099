import { logger } from '@/modules/loggingService'

const log = logger('modules:cookieService:getCookie')

function getCookie(name: string): string | null {
  try {
    // if document is defined, return the cookie
    if (typeof window !== 'undefined') {
      const value = `; ${document.cookie}`
      const parts = value.split(`; ${name}=`)
      if (parts.length === 2) return parts.pop()?.split(';').shift() || null
    }
    return null
  } catch (err: unknown) {
    if (err instanceof Error) {
      log.error(err.message)
      log.error(err.stack)
    }
    return null
  }
}

export default getCookie
