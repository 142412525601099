import styles from './MainWrapper.module.css'

interface LayoutProps {
  children: React.ReactNode;
}

const MainWrapper: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      {children}
    </div>
  )
}

export default MainWrapper
