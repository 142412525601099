'use client'

import Head from 'next/head'
import { MainLayout } from '@/modules/layoutService'
import styles from '@/modules/globalStyleService/404.module.scss'
import { MINISTRY_DESCRIPTION } from '@/modules/stringService'

function Custom500 () {
  return (
    <>
      <Head>
        <title>500 | Internal Server Error</title>
        <meta name="description" content={MINISTRY_DESCRIPTION} />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
        <meta property="og:title" content={'500'} />
        <meta property="og:description" content={'Internal Server Error'} />
      </Head>
      <MainLayout
        links={[
          { href: '/', name: 'Home' },
          { href: '/500', name: '500 - Internal Server Error' }
        ]}
      >
        <div className={styles.main}>
          <h1>500 - Internal Server Error</h1>
        </div>
      </MainLayout>
    </>
  )
}

export default Custom500
