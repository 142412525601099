import propTypes from 'prop-types'
import Link from 'next/link'
import styles from './BreadCrumbBar.module.css'

export interface LinkProp {
  href: string
  name: string
}

/**
 * @function BreadCrumbBar
 * @param props
 * @param props.links
 * @returns
 */
const BreadCrumbBar = (props: { links: LinkProp[] }) => {
  const parts: any[] = []

  props.links.forEach((link, i) => {
    const isLast: boolean = props.links.length - 1 === i

    parts.push(
      <div key={`${i}-anchor`}>
        <Link scroll href={link.href} className={`${styles.anchor} ${isLast ? styles.lastAnchor : ''}`}>{link.name}</Link>
      </div>
    )

    if (!isLast) {
      parts.push(
        <div key={`${i}-split`} className={styles.split}>{'>>'}</div>
      )
    }
  })

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.breadCrumb}>
          {parts.map((part, i) => {
            return (
              <div key={i}>
                {part}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

BreadCrumbBar.propTypes = {
  links: propTypes.array.isRequired
}

export default BreadCrumbBar
