import uuidv4 from './uuidv4'
import titleCase from './titleCase'

export { uuidv4 }
export { titleCase }
export const MINISTRY = 'Stepping Stones International'
export const MINISTRY_DESCRIPTION = 'A network of Christian professionals who design, develop, produce and manage both online and printed content for Missionaries and Christians abroad.'
export const DESCRIPTION = 'A reservoir of Christian gospel and discipleship content for spiritual growth.'
export const RIGHTS_MESSAGE = `© ${new Date().getFullYear()} ${MINISTRY}, All Rights Reserved.`
export const DB = 'steppingStonesIntl'
