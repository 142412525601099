'use client'

import React, { useEffect, useState } from 'react'
import Head from 'next/head'
import { ThemeProvider } from '@mui/material'
import MainWrapper from '@/components/MainWrapper'
import styles from './MainLayout.module.scss'
import { setConsent } from '@/modules/consentService'
import { IConsent } from '@/modules/consentService/interfaces'
import ConsentPopup from '@/components/ConsentPopup'
import MainHeader from '@/components/MainHeader'
import MainFooter from '@/components/MainFooter'
import { LinkProp } from '@/components/BreadCrumbBar'
import { primary as primaryTheme } from '@/modules/themeService'
import { RIGHTS_MESSAGE } from '@/modules/stringService'
import Scroll from '@/components/Scroll'

interface LayoutProps {
  links: LinkProp[]
  children: React.ReactNode;
}

const MainLayout: React.FC<LayoutProps> = ({ links, children }) => {
  const [consent, changeConsent] = useState<IConsent | null>(null)
  const [
    showRightsMessage,
    setShowRightsMessage
  ] = useState<boolean>(true)

  // Effect to run only on client side
  useEffect(() => {
    let val: any = window.localStorage.getItem('consent') || null
    if (val) val = JSON.parse(val)
    // console.log({ val })
    // setConsent({ ...consent, ...val })
    if (val) changeConsent({ ...val })
  }, [])

  const updateConsent = (form: IConsent | null): void => {
    setConsent(form)
    changeConsent(form)
  }

  return (
    <>
      <Head>
        {/* <meta name="description" content={META.description} />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" /> */}
        {/* Open Graph */}
        {/* <meta property="og:title" content={META.defaultTitle} />
        <meta property="og:description" content={META.description} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={META.defaultTitle} /> */}
        {/* <meta property="og:image" content="/img/og-image@630x-100.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" /> */}
        <style>{'body { background-color: var(--background-color); }'}</style>
      </Head>
      <Scroll />
      <ThemeProvider theme={primaryTheme}>
        <div className={styles.wrapper}>
          {/* <Header /> */}
          <MainWrapper>
            <div className={styles.wrap}>
              {showRightsMessage &&
                <div className={styles.leftSection}>
                  {RIGHTS_MESSAGE}
                </div>
              }
              <div className={styles.rightSection}>
                <MainHeader links={links} />
                {children}
              </div>
            </div>
            {consent === null &&
              <ConsentPopup updateConsent={(obj) => updateConsent(obj)} />
            }
          </MainWrapper>
          <MainFooter
            toggleRightsMessage={(bool: boolean) => setShowRightsMessage(bool)}
          />
        </div>
      </ThemeProvider>
    </>
  )
}

export default MainLayout
