import primary from './primary'
import secondary from './secondary'
import lightTheme from './lightTheme'
import darkTheme from './darkTheme'
import clientGetTheme from './clientGetTheme'

export { primary }
export { secondary }
export { lightTheme }
export { darkTheme }
export { clientGetTheme }

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  primary,
  secondary,
  lightTheme,
  darkTheme,
  clientGetTheme
}
