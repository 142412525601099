import IConsent from './interfaces/IConsent'
import { logger } from '@/modules/loggingService'

const log = logger('modules:consentService:setConsent')

function setConsent(consent: IConsent | null): void {
  try {
    if (!consent) return undefined

    if (typeof window !== 'undefined') {
      // const consentMode = {
      //   'analytics_storage': consent.analytics ? 'granted' : 'denied',
      //   'functionality_storage': consent.necessary ? 'granted' : 'denied',
      //   'security_storage': consent.necessary ? 'granted' : 'denied',
      //   'personalization_storage': consent.preferences ? 'granted' : 'denied'
      // }
      window.localStorage.setItem('consent', JSON.stringify(consent))

      // set consent cookie to 'yes' or 'no' for 1 year
      document.cookie = [
        `consent=${consent.necessary ? 'yes' : 'no'}`,
        `max-age=${60 * 60 * 24 * 365}`, // 1 year
        'path=/',
        'samesite=strict',
        `secure=${process.env.NODE_ENV === 'production'}`
      ].join('; ')
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      log.error(err.message)
      log.error(err.stack)
    }
  }
}

export default setConsent
