import { createTheme } from '@mui/material/styles'

const primary = createTheme({
  palette: {
    primary: {
      main: '#4e54c8'
    },
    secondary: {
      main: '#C68E3F'
    }
  }
})

export default primary
