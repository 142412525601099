import { createTheme } from '@mui/material/styles'

const secondary = createTheme({
  palette: {
    primary: {
      main: '#313131'
    },
    secondary: {
      main: '#C68E3F'
    }
  }
})

export default secondary
