import getCookie from './getCookie'
import setCookie from './setCookie'

export { getCookie }
export { setCookie }

const cookieService = {
  getCookie,
  setCookie
}

export default cookieService
